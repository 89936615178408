<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            General Receipt
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs2 sm2>
                <v-text-field
                  :disabled="!sodEnable"
                  v-model="enrollCode"
                  class="pa-0"
                  label="Enroll Code"
                  placeholder="ST-76001"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs4 sm4>
                <v-autocomplete
                  :search-input.sync="search"
                  :items="students"
                  hide-no-data
                  :loading="studentLoading"
                  @keyup="searchEnable = true"
                  @change="searchEnable = false"
                  class="pa-0"
                  label="Search Students"
                  v-model="form.name"
                  :hint="!students.length ? 'Type atleast 3 characters' : ''"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs2 sm2 v-if="enrollCode">
                <v-select
                  :items="paymentMethod"
                  class="pa-0"
                  label="Payment Method"
                  v-model="form.via"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3>
                <transition
                  name="animate-css-transition"
                  enter-active-class="animated fadeInRight"
                  leave-active-class="animated fadeOutRight"
                >
                  <search-button
                    style=""
                    :disabled="!students.length || enrollCode.length"
                    permission="billing-report-collection"
                    @action="searchStudent()"
                  >
                    Search
                  </search-button>
                </transition>
              </v-flex>

              <v-flex xs3 sm3 v-if="bankInfo">
                <v-text-field
                  v-model="form.cheque_no"
                  class="pa-0"
                  label="Cheque No"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm3 v-if="bankInfo">
                <v-text-field
                  v-model="form.bank_name"
                  class="pa-0"
                  label="Bank Name"
                  outlined
                  dense
                />
              </v-flex>
            </v-card-title>
            <div class="tab-panel-" v-if="studentData">
              <v-tabs v-model="model" color="white" slider-color="primary">
                <v-tab
                  v-for="(tab, i) in tabItems"
                  :key="i"
                  :href="`#tab-${i}`"
                >
                  {{ tab }}
                </v-tab>
              </v-tabs>
            </div>
          </v-card>
          <v-container v-if="studentData">
            <div class="dash-container info-tab-section" style="width: 100%">
              <!-- <strong v-if="sodEnable">
                <v-icon small>date_range</v-icon>
                Transaction Date : {{ sod_date }}</strong
              > -->
              <v-container v-if="sod_date && sod_date.length > 0">
                <alert-message
                  :extraSmall="true"
                  :title="'SOD date (' + sod_date + ')'"
                  message="The transaction being created will be conducted on the above SOD date"
                ></alert-message>
              </v-container>
              <br />
              <div v-if="model === 'tab-0'" class="general-receipt">
                <v-btn
                  v-if="studentData"
                  @click="addRow"
                  outlined
                  :disabled="form.loading"
                  style="float:right; margin-top: -5px;"
                >
                  <v-icon small class="mr-2"> add</v-icon>
                  add
                </v-btn>
                <v-spacer></v-spacer>

                <v-card-title class="title" v-if="studentData">
                  <v-flex xs12 sm12 text-xs-right>
                    <v-layout
                      row
                      wrap
                      v-for="(row, key) in form.details"
                      :key="key"
                    >
                      <v-flex xs12 sm5>
                        <v-select
                          :readonly="form.loading"
                          v-model="row.fee_head_id"
                          :items="fee_heads"
                          label="Select Fee Head"
                          @change="feeHeadChange(row)"
                          name="fee_head_id"
                          :height="25"
                          outlined
                          dense
                        />
                      </v-flex>
                      <v-flex xs12 sm5>
                        <v-text-field
                          :readonly="form.loading"
                          v-model="row.amount"
                          type="number"
                          name="amount"
                          label="Total Amount"
                          :height="25"
                          @keyup="sumNetAmount(row)"
                          outlined
                          dense
                        />
                      </v-flex>
                      <v-flex xs12 sm1 text-xs-right>
                        <v-btn
                          v-if="!(form.details.length < 2)"
                          @click="removeElement(row)"
                          class="error white--text"
                          outlined
                          icon
                          small
                        >
                          <v-icon small class="white--text">remove</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm10>
                    <v-textarea
                      rows="4"
                      v-model="form.remarks"
                      label="Remarks"
                      hint="Comment any other information"
                      outlined
                      dense
                    />
                  </v-flex>
                  <v-flex xs5 sm5>
                    <transition
                      name="animate-css-transition"
                      enter-active-class="animated fadeInRight"
                      leave-active-class="animated fadeOutRight"
                    >
                      <v-text-field
                        label="Payment Amount (Being Paid)"
                        type="number"
                        v-model="payment_amount"
                        disabled
                        outlined
                        dense
                      />
                    </transition>
                  </v-flex>
                  <v-flex xs10 sm10 class="text-xs-right">
                    <transition
                      name="animate-css-transition"
                      enter-active-class="animated fadeInRight"
                      leave-active-class="animated fadeOutRight"
                    >
                      <v-btn
                        v-if="$auth.can('receipt-create')"
                        block
                        color="primary"
                        @click="confirm('fee-gen')"
                        :disabled="
                          parseFloat(payment_amount) <= 0 ||
                            isNaN(parseFloat(payment_amount)) ||
                            form.remarks.length < 4
                        "
                        ma-0
                        >PAY NOW
                      </v-btn>
                    </transition>
                  </v-flex>
                </v-card-title>
                <v-card-title v-if="studentData"> </v-card-title>
              </div>
            </div>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="confirmDialog" persistent max-width="460px">
      <v-card>
        <!--  <v-card-title class="title pa-3">
                     <p>{{payment_amount.currency()}}</p>
                     Are you sure want to pay this amount?
                 </v-card-title> -->
        <v-card-title class="title pa-3 warning white--text">
          <v-icon class="mr-2 white--text">warning</v-icon>
          Please, wait!
        </v-card-title>
        <v-card-text class="ma-0">
          <br />
          <v-chip class="mb-2">{{
            printAmount ? printAmount.currency() : 0
          }}</v-chip>
          <br />
          <v-chip color="green" text-color="white">{{
            convertNumberToWords(printAmount, true)
          }}</v-chip>
          <br />
          Are you sure you want to pay this amount?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" outlined small @click="confirmDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            :loading="this.saving_payment"
            color="warning"
            outlined
            small
            @click="submit"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";

import { convertNumberToWords, formatNumber } from "@/library/helpers";
// import {formatNumber} from '@/library/helpers'
import Form from "@/library/Form";

export default {
  data: () => ({
    form: new Form(
      {
        enroll_id: "",
        enroll_code: "",
        total: 0,
        section_id: "",
        via: "cash",
        cheque_no: "",
        bank_name: "",
        remarks: "",
        details: [
          {
            fee_head_id: "",
            fee_head: "",
            amount: "",
          },
        ],
      },
      "api/fee-payment/general-receipt"
    ),

    admissionDetail: [
      {
        fee_head_id: "",
        fee_head: "",
        fee_id: "",
        fee_head_amount: "",
        total_amount: "",
        discount: "",
        discount_per: 0,
        scholar_per: 0,
      },
    ],
    search: null,
    // name:'',
    grades: [],
    studentInfo: {},
    studentData: false,
    studentLoading: false,
    payment_amount: 0,
    payment_amount_detail: 0,
    enrollCode: "",
    // enrollCode:'',
    enroll_id: "",
    months: [],
    month: "",
    generate: false,
    print: false,
    billingSetting: {},
    confirmDialog: false,
    items: [],
    name: null,
    roll: null,
    grade: null,
    section: null,
    fee_heads: [],
    datavalue: false,
    bankInfo: false,
    sod_date: "",
    model: "tab-0",
    sodEnable: false,
    newAdmission: false,
    printAmount: "",
    paymentDetail: [{ ids: "", amount: 0, discount: 0 }],
    paymentMethod: [
      { text: "Cash", value: "cash" },
      { text: "Cheque", value: "cheque" },
    ],
    adv: "",
    totalSum: 0,
    students: [],
    ledgerHeads: [],
    tabItems: ["General Receipt"],
    offers: [],
    searchEnable: true,
    saving_payment: false,
  }),

  computed: {
    ...mapState(["batch"]),
  },

  mounted() {
    this.currentSod();
    // this.getFeeHead();
  },

  watch: {
    batch: function(value) {},
    enrollCode: function(value) {
      this.studentData = false;
      this.items.data = [];
      this.form.name = "";
      if (value && value.length > 7) {
        this.searchStudent();
      }
      this.admissionDetail = [
        {
          fee_head_id: "",
          fee_head: "",
          fee_id: "",
          fee_head_amount: "",
          total_amount: "",
          paid_amount: "",
          discount: "",
          discount_per: 0,
          scholar_per: 0,
        },
      ];
    },
    "form.via": function(value) {
      if (this.form.via == "cheque") this.bankInfo = true;
      else this.bankInfo = false;
    },

    search(val) {
      if (!this.searchEnable) return false;
      if (!val) {
        this.students = [];
        this.studentLoading = false;
        return;
      }
      if (this.isLoading) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.studentLoading = true;
      this.timer = setTimeout(() => {
        this.$rest
          .get(
            "api/search-student-grade-section?search=" + this.search.toString()
          )
          .then(({ data }) => {
            this.students = data.data.map((item) => {
              return {
                value: item.enroll_code,
                text:
                  item.name +
                  " (" +
                  item.grade +
                  item.section +
                  ") - " +
                  item.roll,
                enrollCode: item.enroll_code,
              };
            });
          })
          .catch((err) => {})
          .finally(() => (this.studentLoading = false));
      }, 500);

      // if (val && val.length > 2 && this.students.length < 1) {
      //     this.studentLoading = true;
      //
      //     this.$rest.get('api/search-student-grade-section?search=' + this.search.toString())
      //         .then(({data}) => {
      //             this.students = data.data.map(item => {
      //                 return {
      //                     value: item.enroll_code,
      //                     text: item.name + ' (' + item.grade + item.section + ') - ' + item.roll,
      //                     enrollCode: item.enroll_code
      //                 }
      //             });
      //         }).catch(err => {
      //     }).finally(() => (this.studentLoading = false))
      // }
    },
    "form.name": function(value) {
      this.students.filter((res) => {
        if (res.value == value) {
          this.enrollCode = res.enrollCode;
        }
      });
    },
  },

  methods: {
    convertNumberToWords,

    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&batchId=" +
        this.batch.id +
        "&gradeId=" +
        this.grade
      );
    },

    get(params) {
      if (this.enrollCode) {
        let query = [null, undefined].includes(params)
          ? this.queryString()
          : params;
        this.form.get(null, query).then(({ data }) => {
          this.pagination.totalItems = data.meta.total;
        });
      }
    },

    searchStudent() {
      this.$rest
        .get("/api/fee-payment/" + this.enrollCode)
        .then(({ data }) => {
          this.studentData = true;
          this.studentInfo = data.student;
          this.students = [
            {
              value: this.enrollCode,
              enrollCode: this.enrollCode,
              text:
                this.studentInfo.name +
                " (" +
                this.studentInfo.grade +
                "-" +
                this.studentInfo.section +
                ") - " +
                this.studentInfo.roll,
            },
          ];

          this.form.name = this.enrollCode;
          // this.payment_amount = data.totalSum;
          this.payment_amount = 0;
          this.form.payment_amount = 0;
          this.getFeeHead();
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: "No record found.",
            status: "error",
          });
        });
    },
    submit() {
      if (this.payment_amount < 1)
        this.$events.fire("notification", {
          message: "Please fill the payment amount.",
          status: "error",
        });
      this.saving_payment = true;
      this.form.enroll_id = this.studentInfo.enroll_id;
      this.form.enroll_code = this.studentInfo.enroll_code;
      this.form.total = this.payment_amount;
      this.form.section_id = this.studentInfo.section_id;
      this.form.fireFetch = false;
      this.form
        .store()
        .then(({ data }) => {
          (this.enrollCode = ""), (this.students = []);
          this.saving_payment = false;
          this.confirmDialog = false;

          window.open(data.data.url);
        })
        .finally((err) => {
          this.saving_payment = false;
        });
    },
    submitNewAdmission() {
      this.$rest
        .post("/api/invoice/new-admission", {
          enroll_id: this.studentInfo.enroll_id,
          amount: this.payment_amount_detail,
          fees: this.admissionDetail,
          sumAmount: this.payment_amount_detail,
          via: this.form.via,
          bank_name: this.form.bank_name,
          cheque_no: this.form.cheque_no,
          remarks: this.form.remarks,
        })
        .then((data) => {
          this.enrollCode = "";
          this.students = [];
          this.confirmDialog = false;
          this.$events.fire("notification", {
            message: data.data.message,
            status: "success",
          });

          if (!["", null, undefined].includes(data.data.data.url)) {
            window.open(data.data.data.url);
          }
        })
        .catch((e) => {
          this.confirmDialog = false;
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    currentSod() {
      this.$rest.get("/api/sod-current").then(({ data }) => {
        if (data.sod) {
          this.sodEnable = true;
          this.sod_date = data.sod;
        } else {
          this.sodEnable = false;
          this.sod_date =
            "Start of day has not been set. Please contact your admin.";
        }
      });
    },
    addRow: function() {
      if ([undefined, null].includes(this.form.details)) {
        this.form.details = [{ fee_head_id: null, fee_head: "", amount: 0 }];
      } else {
        this.form.details.push({ fee_head_id: null, fee_head: "", amount: 0 });
      }
    },

    removeElement: function(row) {
      var index = this.form.details.indexOf(row);
      this.form.details.splice(index, 1);
      this.sumNetAmount(row);
    },

    addRowD: function() {
      if ([undefined, null].includes(this.form.details)) {
        this.admissionDetail = [
          {
            fee_head_id: "",
            fee_head: "",
            fee_id: "",
            fee_head_amount: "",
            total_amount: "",
            paid_amount: "",
            discount: "",
            discount_per: 0,
            scholar_per: 0,
          },
        ];
      } else {
        this.admissionDetail.push({
          fee_head_id: "",
          fee_head: "",
          fee_id: "",
          fee_head_amount: "",
          total_amount: "",
          paid_amount: "",
          discount: "",
          discount_per: 0,
          scholar_per: 0,
        });
      }
    },

    removeElementD: function(row) {
      var index = this.admissionDetail.indexOf(row);
      this.admissionDetail.splice(index, 1);
      this.sumNetAmount(row);
    },

    sumNetAmount(item) {
      if (![undefined, null, NaN].includes(item)) {
        this.payment_amount = this.form.details
          .map((it) => parseFloat(it.amount))
          .reduce((x, y) => x + y, 0);
      }
    },
    sumNetAmountD(item) {
      if (![undefined, null, NaN].includes(item)) {
        let sum = 0;
        this.admissionDetail.map((res) => {
          sum += parseFloat(res.paid_amount);
          if (res.discount > 0) {
            sum -= parseFloat(res.discount);
          }
        });
        this.payment_amount_detail = sum;
      }
    },
    feeHeadChange(item) {
      this.fee_heads.filter((res) => {
        if (res.value == item.fee_head_id) {
          // item.amount = res.amount;
          item.fee_head = res.text;
        }
      });
      // this.sumNetAmount(item);
    },
    feeHeadChangeD(item) {
      this.fee_heads.filter((res) => {
        if (res.value === item.fee_head_id) {
          item.fee_head_amount = res.amount;
          item.total_amount = res.amount;
          item.fee_head = res.text;
          item.paid_amount = res.amount;
          item.fee_id = res.fee_id;
          item.discount_per = 0;
          item.scholar_per = 0;
        }
      });

      if (this.offers.length > 0) {
        this.offers.filter((r) => {
          if (r.fee_id === item.fee_id) {
            item.discount_per = r.discount_percentage;
            item.scholar_per = r.scholar_percentage;
            item.total_amount =
              item.fee_head_amount -
              (item.fee_head_amount * (r.discount_percentage / 100) +
                item.fee_head_amount * (r.scholar_percentage / 100));
            item.paid_amount =
              item.fee_head_amount -
              (item.fee_head_amount * (r.discount_percentage / 100) +
                item.fee_head_amount * (r.scholar_percentage / 100));
          }
        });
      }
      this.sumNetAmountD(item);

      // console.log(this.offers);

      //  item.discount = 500;
      // console.log(item);
      // this.sumNetAmount(item);
    },
    getFeeHead() {
      this.$rest
        .get(
          "/api/fee-head?itemsPerPage=30&enrollId=" + this.studentInfo.enroll_id
        )
        .then(({ data }) => {
          this.fee_heads = data.data.map((res) => {
            return {
              value: res.id,
              text: res.title,
              amount: res.amount,
              fee_id: res.fee_id,
            };
          });
          this.offers = data.offers;
        });
    },
    confirm(data) {
      if (data === "fee-gen") {
        this.printAmount = this.payment_amount;
        this.newAdmission = false;
      } else if (data === "new-ad") {
        this.printAmount = this.payment_amount_detail;
        this.newAdmission = true;
      }

      this.confirmDialog = true;
      if (
        this.form.via === "cheque" &&
        (!this.form.cheque_no || !this.form.bank_name)
      ) {
        this.$events.fire("notification", {
          message: "Please fill the  Bank/Cheuqe information correctly.",
          status: "error",
        });
      } else {
        this.confirmDialog = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
table.v-datatable.v-table.theme--light {
  width: 100%;
}
</style>
